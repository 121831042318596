import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';

import { Hero } from '@latitude/hero';
import Section from '@latitude/section';
import { List, ListItem } from '@latitude/list';
import {
  BREAKPOINT,
  COLOR,
  FONT_SIZE,
  LINE_HEIGHT,
  ALIGN,
  FONT_WEIGHT,
  MARGIN
} from '@latitude/core/utils/constants';
import { Lframe } from '@latitude/lframe';
import { Text } from '@latitude/text';
import { ImportantInformation } from '@latitude/important-information';
import { Heading3 } from '@latitude/heading';
import { Link } from '@latitude/link';
import SvgInline from '@latitude/svg-inline';

import Layout from '@/components/layout';
import SvgInlineLocal from '@/components/SvgInline/SvgInline';
import DegreesCardMicrositeHeader from './_28d-microsite-header';

const heroImage = require('./images/lframe-contact-us.png');

function ContactUsPage(props) {
  return (
    <Layout noFooter location={props.location}>
      <main
        className="navigation-spacer"
        css={`
          margin-top: 0 !important;
          background-color: #f8f8f8;
          .microsite-header {
            z-index: 2000 !important;
          }
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`https://www.latitudefinancial.com.au/credit-card/28-degrees/contact-us`}
          />
          <title>
            Contact Us | Latitude 28&deg; Global Platinum Mastercard |
            International Credit Card
          </title>
          <meta
            name="description"
            content="If you need to contact our team, you can do this by phone or mail. Find our contact information here."
          />
        </Helmet>
        <DegreesCardMicrositeHeader />

        <div css="position: relative;">
          <Lframe
            css={`
              && {
                background-image: url(${heroImage});
              }
            `}
          />

          <Hero
            css={`
              .HeroContent {
                background-color: ${COLOR.WHITE};
                margin-bottom: -24px;
                @media (min-width: ${BREAKPOINT.MD}) {
                  background-color: transparent;
                  min-height: 380px;
                  margin-bottom: inherit;
                }
                @media (min-width: ${BREAKPOINT.LG}) {
                  padding-right: 80px;
                }
                @media (min-width: ${BREAKPOINT.XL}) {
                  padding-right: 0;
                }
              }
            `}
            title="Something on your mind? Contact us"
            text="Our team is on hand to help you with all your Latitude 28&deg; Global queries."
          />
        </div>

        <Section
          id="info"
          css={`
            && {
              padding: 15px 0;
              @media (max-width: ${BREAKPOINT.MD}) {
                border-top: 1px solid #e9ebec;
              }
              @media (min-width: ${BREAKPOINT.LG}) {
                padding: 0 0 40px;
              }
            }
          `}
        >
          <FlexContainer>
            <TileContainer>
              <Tile>
                <TileIcon>
                  <SvgInlineLocal name="contact" />
                </TileIcon>
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  marginBottom={MARGIN.M8}
                >
                  How to get in touch
                </Heading3>
                <Text
                  fontSize={FONT_SIZE.SMALL}
                  lineHeight={LINE_HEIGHT.TIGHT.NORMAL}
                  align={ALIGN.CENTER}
                >
                  If you have a question or need help, you can contact us
                  through one of the options.
                </Text>
              </Tile>
              <Tile>
                <List pointless>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>
                      Within Australia call
                    </Text>{' '}
                    <Link
                      className="no-wrap"
                      target="_blank"
                      href="tel:1300 552 079"
                      disableDefaultEventTracking
                    >
                      1300 552 079
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>
                      When overseas call
                    </Text>{' '}
                    <Link
                      className="no-wrap"
                      target="_blank"
                      href="tel:+61 2 8747 8178"
                      disableDefaultEventTracking
                    >
                      +61 2 8747 8178
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>Contact us on</Text>{' '}
                    <Link
                      href="skype:twentyeightdegrees.com.au?call"
                      target="_blank"
                      disableDefaultEventTracking
                    >
                      Skype
                    </Link>{' '}
                    (limited to voice calls only)
                  </ListItem>
                  <ListItem>
                    <Text
                      fontSize={FONT_SIZE.SMALL}
                      lineHeight={LINE_HEIGHT.TIGHT}
                    >
                      Mon to Fri: 8:30am&ndash;6pm (AEST)
                      <br />
                      Weekends: Closed
                    </Text>
                  </ListItem>
                </List>
              </Tile>
              <Tile>
                <List pointless>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>Contact us on</Text>{' '}
                    <Link
                      target="_blank"
                      href="https://www.facebook.com/latitudefinancialservices/"
                      disableDefaultEventTracking
                    >
                      Facebook
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Text
                      fontSize={FONT_SIZE.SMALL}
                      lineHeight={LINE_HEIGHT.TIGHT}
                    >
                      Mon to Fri: 9am&ndash;6pm (AEST)
                      <br />
                      Weekends: Closed
                    </Text>
                  </ListItem>
                </List>
              </Tile>
              <Tile>
                <List pointless>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>Fax</Text> 1300 369
                    905
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>
                      Latitude 28&deg; Global <br />
                      Customer Solutions
                    </Text>{' '}
                    <Text>GPO Box 1818</Text>
                    <Text>Melbourne VIC 3001</Text>
                  </ListItem>
                </List>
              </Tile>
            </TileContainer>
          </FlexContainer>

          <FlexContainer>
            <TileContainer half>
              <Tile purple>
                <TileIcon>
                  <SvgInlineLocal name="lost-card" />
                </TileIcon>
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  marginBottom={MARGIN.M8}
                >
                  Lost or stolen card
                </Heading3>
                <Text
                  fontSize={FONT_SIZE.SMALL}
                  lineHeight={LINE_HEIGHT.TIGHT.NORMAL}
                  align={ALIGN.CENTER}
                >
                  Call Customer Solutions immediately
                </Text>
              </Tile>
              <Tile>
                <List pointless>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>
                      Within Australia call
                    </Text>{' '}
                    <Link
                      className="no-wrap"
                      target="_blank"
                      href="tel:1800 005 809"
                      disableDefaultEventTracking
                    >
                      1800 005 809
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>
                      When overseas call
                    </Text>{' '}
                    <Link
                      className="no-wrap"
                      target="_blank"
                      href="tel:+61 287 478 178"
                      disableDefaultEventTracking
                    >
                      +61 287 478 178
                    </Link>{' '}
                    or{' '}
                    <Link
                      className="no-wrap"
                      target="_blank"
                      href="tel:+1 636722 7111"
                      disableDefaultEventTracking
                    >
                      +1 636722 7111
                    </Link>{' '}
                    <br />
                    (Mastercard Global Service)
                  </ListItem>
                  <ListItem>
                    <Text
                      fontSize={FONT_SIZE.SMALL}
                      lineHeight={LINE_HEIGHT.TIGHT}
                    >
                      Open 24 hours a day 365 days a year
                    </Text>
                  </ListItem>
                </List>
              </Tile>
            </TileContainer>

            <TileContainer half>
              <Tile purple>
                <TileIcon>
                  <SvgInlineLocal name="declined-card" />
                </TileIcon>
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  marginBottom={MARGIN.M8}
                >
                  Declined transaction or suspect fraudulent activity
                </Heading3>
                <Text
                  fontSize={FONT_SIZE.SMALL}
                  lineHeight={LINE_HEIGHT.TIGHT.NORMAL}
                  align={ALIGN.CENTER}
                >
                  Call Customer Solutions immediately
                </Text>
              </Tile>
              <Tile>
                <List pointless>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>
                      Within Australia call
                    </Text>{' '}
                    <Link
                      className="no-wrap"
                      target="_blank"
                      href="tel:1300 552 079"
                      disableDefaultEventTracking
                    >
                      1300 552 079
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Text fontWeight={FONT_WEIGHT.MEDIUM}>
                      When overseas call
                    </Text>{' '}
                    <Link
                      className="no-wrap"
                      target="_blank"
                      href="tel:+61 2 8747 8178"
                      disableDefaultEventTracking
                    >
                      +61 2 8747 8178
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Text
                      fontSize={FONT_SIZE.SMALL}
                      lineHeight={LINE_HEIGHT.TIGHT}
                    >
                      Open 24 hours a day 365 days a year
                    </Text>
                  </ListItem>
                </List>
              </Tile>
            </TileContainer>
          </FlexContainer>

          <FlexContainer>
            <TileContainer half>
              <Tile grey>
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  marginBottom={MARGIN.M8}
                >
                  Dispute a <br />
                  transaction
                </Heading3>
                <StyleText fontSize={FONT_SIZE.SMALL} align={ALIGN.CENTER}>
                  Before contacting the merchant or Customer Solutions Team
                  please take a moment to review the relevant FAQs for
                  transaction disputes.
                </StyleText>
                <Link
                  href="/credit-cards/28-degrees/faqs/"
                  button="tertiary"
                  css="display:inline-block; font-weight:400; margin:22px auto 0; font-size:14px;"
                  disableDefaultEventTracking
                >
                  View FAQs
                </Link>
              </Tile>
            </TileContainer>

            <TileContainer half>
              <Tile grey>
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  marginBottom={MARGIN.M8}
                >
                  Complaints
                  <br />
                  &nbsp;
                </Heading3>
                <StyleText fontSize={FONT_SIZE.SMALL} align={ALIGN.CENTER}>
                  We know sometimes things don't go as well as they should and
                  problems arise. If this happens to you please let us know so
                  we can fix the problem.
                </StyleText>
                <Link
                  href="https://latitudefs.zendesk.com/hc/en-au/articles/360020889237-Making-a-Complaint"
                  button="tertiary"
                  target="_blank"
                  css="display:inline-block; font-weight:400; margin:22px auto 0; font-size:14px;"
                  disableDefaultEventTracking
                >
                  Complaints &nbsp;
                  <SvgInline name="external-link" />
                </Link>
              </Tile>
            </TileContainer>
          </FlexContainer>

          <FlexContainer leftAlign>
            <TileContainer quarter>
              <Tile grey>
                <Heading3
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  marginBottom={MARGIN.M8}
                >
                  Hardship and <br />
                  financial difficulty
                </Heading3>
                <Text fontSize={FONT_SIZE.SMALL} align={ALIGN.CENTER}>
                  If you're having difficulties making repayments, it's best to
                  get in touch as soon as possible - we have a dedicated
                  Hardship Team to assist.
                </Text>
                <Link
                  href="/hardship-care/"
                  button="tertiary"
                  css="display:inline-block; font-weight:400; margin:22px auto 0; font-size:14px;"
                  disableDefaultEventTracking
                >
                  Hardship Care &nbsp;
                  <SvgInline name="external-link" />
                </Link>
              </Tile>
            </TileContainer>
          </FlexContainer>
        </Section>
        <ImportantInformation
          data={{
            content: {
              importantInformationSectionOne: [
                'Important information about Skype™ and your security',
                'For your online safety, you must always use the “Contact us on Skype” link on the Latitude Financial Services website at 28degreescard.com.au when contacting Latitude Financial Services via Skype™. You should always connect directly and not through a bookmark or a link. Latitude Financial Service has only one Skype™ Account, which is twentyeightdegrees.com.au.',
                'Please note that the Skype™ service is not encrypted and that the internet is an inherently insecure medium. If you are concerned about your privacy information, you should not use Skype™ to contact Latitude Financial Services.'
              ],
              importantInformationSectionTwo: [
                'Latitude Financial Services will not initiate contact with you via Skype™. Do not respond to incoming calls via Skype™ purporting to be from Latitude Financial Services and instead report them to <a href="mailto:fraud.query@latitudefinancial.com">fraud.query@latitudefinancial.com</a>.',
                'If you prefer to use the Skype™ Out service, you can contact Latitude Financial Services at +61 9 8198 222. Latitude Financial Services Skype™ service is limited to voice calls only.'
              ]
            }
          }}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
        />
      </main>
    </Layout>
  );
}

const Tile = styled.div`
  font-size: 14px;
  line-height: 1.43;
  font-weight: 300;
  margin: 0 24px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding: 24px 27px;

  && ul {
    margin: 0;
    list-style-type: none !important;
  }

  && ul li {
    margin: 0 0 10px;
    &:last-child {
      margin: 0;
    }
  }

  p:last-child {
    margin: 0;
  }

  h3 {
    font-size: 20px;
    line-height: 26px;
  }

  &:first-child {
    background-color: #e8f4ff;
    text-align: center;
    border-bottom: 0;
    margin: 0;
    padding: 24px;

    ${props =>
      props.purple &&
      css`
        background-color: #f2f2fe;
      `}

    ${props =>
      props.pink &&
      css`
        background-color: #ffeff7;
      `}

    ${props =>
      props.grey &&
      css`
        background-color: #f2f2f2;
      `}
  }
  &:last-child {
    border-bottom: 0;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    border-bottom: 0;
    padding: 32px 24px;
    flex: 0 1 280px;
    margin-left: 0;
    margin-right: 0;
    min-height: 296px;

    &:after {
      display: block;
      width: 1px;
      height: 80%;
      content: '';
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      background-color: #e5e5e5;
    }

    &:first-child, &:last-child {
      &:after {
        display: none;
      }
    }
  }

  li {
    font-size: 16px;
    line-height: 1.5;
    small {
      font-size: 14px;
    }
  }
`;

const TileContainer = styled.div`
  display: flex;
  background: ${COLOR.WHITE};
  flex-direction: column;
  max-width: 340px;
  margin: 0 auto 30px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.07);

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;

    ${props =>
      props.half &&
      css`
        display: inline-flex;
        flex: 0 1 50%;
        max-width: 535px;
        ${Tile} {
          &:first-child {
            flex: 0 1 301px;
          }
        }
        &:only-child {
          flex: 0 1 calc(50% - 15px);
        }
      `} ${props =>
      props.quarter &&
      css`
        display: inline-flex;
        flex: 0 1 23%;
        max-width: 277px;
      `};
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    ${props =>
      props.half &&
      css`
        ${Tile} {
          &:first-child {
            flex: 0 1 302px;
          }
        }
      `} ${props =>
      props.quarter &&
      css`
        display: inline-flex;
        flex: 0 1 calc(25% - 30px);
        max-width: 277px;
        &:only-child {
          min-width: 277px;
        }
      `};
  }
`;

const StyleText = styled(Text)`
  @media (min-width: ${BREAKPOINT.LG}) {
    min-height: 144px;
  }
  @media (min-width: ${BREAKPOINT.XL}) {
    min-height: 120px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 340px;
  margin: 0 auto;

  @media (min-width: ${BREAKPOINT.LG}) {
    flex-direction: row;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;
    justify-content: space-between;

    ${props =>
      props.leftAlign &&
      css`
        justify-content: flex-start;
      `};
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    margin-bottom: 35px;
  }

  ${TileContainer} {
    margin-left: 0;
    margin-right: 0;

    @media (min-width: ${BREAKPOINT.LG}) {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const TileIcon = styled.div`
  display: flex;
  margin: 0 auto 4px;
  width: 96px;
  height: 96px;
  align-items: center;
  justify-content: center;
`;

export default ContactUsPage;
